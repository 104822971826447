<template>
  <div>
    <v-data-table
      show-select
      v-model="selected"
      :headers="headers"
      :items="data"
      :loading="loading"
      hide-default-footer
      :items-per-page="24"
      class="elevation-1 text-overflow my-10"
      @item-selected="handleSelectedRow"
      item-key="uuid"
      @toggle-select-all="handleSelectedRow"
      @click:row="previewItem"
    >
      <!-- style="cursor:pointer" -->
      <template v-slot:top>
        <v-toolbar flat height="70">
          <!-- <v-row class="mt-4"> -->
          <v-col cols="5" sm="6" md="4" lg="4" xl="3" class="pl-0">
            <v-row justify="start" class="ml-1">
              <v-toolbar-title class="headline" :data-cy="title">
                <span>
                  {{ title }}
                </span>
                <v-btn
                  v-if="canUseKnowledgeBaseBtn"
                  @click="handleHelp"
                  icon
                  color="primary"
                >
                  <v-icon>mdi-help-circle</v-icon>
                </v-btn>
              </v-toolbar-title>
            </v-row>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="5" lg="4" xl="4" class="mt-7">
            <v-row justify="center">
              <v-col cols="5">
                <v-select
                  v-model="boat"
                  :items="boatOptions"
                  item-text="boat_name"
                >
                </v-select>
              </v-col>
              <v-col cols="3" md="4" sm="4">
                <v-select
                  v-model="statusFilterSelected"
                  :items="statusFilterOptions"
                  item-text="name"
                  item-value="value"
                  color="primary"
                  @change="submitSearch"
                ></v-select>
              </v-col>
            </v-row>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="2"> </v-col>
        </v-toolbar>
      </template>
      <!-- <template v-slot:item.data-table-select="{ item, isSelected, headers }">
        <v-checkbox
          v-if="
            item.crm_internal_task === null &&
              item.service_status !== 'complete'
          "
          color="primary"
          v-model="selected"
          :value="item.uuid"
        ></v-checkbox>
      </template> -->
      <template v-slot:item.overdueMileage="{ item }">
        <span>
          <span
            v-if="
              getEngineHours(item.engine_seconds) -
                getEngineHours(item.should_be_engine_seconds_before) >
                0
            "
          >
            {{
              getEngineHours(item.engine_seconds) -
                getEngineHours(item.should_be_engine_seconds_before)
            }}
            hours ago
          </span>
          <span v-else>
            {{
              getEngineHours(item.engine_seconds) -
                getEngineHours(item.should_be_engine_seconds_before)
            }}
            hours left
          </span>
        </span>
      </template>
      <template v-slot:item.overdueTime="{ item }">
        <span v-if="getDaysLeftDate(item.should_be_done_before) !== null">
          <span v-if="getDaysLeftDate(item.should_be_done_before) > 0">
            {{ getDaysLeftDate(item.should_be_done_before) }}
            days left</span
          ><span v-if="getDaysLeftDate(item.should_be_done_before) <= 0">
            {{ Math.abs(getDaysLeftDate(item.should_be_done_before)) }}
            days ago</span
          ></span
        >
      </template>
      <template v-slot:item.doneMileage="{ item }">
        <span v-if="item.engine_seconds_completed !== null">
          <span>{{ getEngineHours(item.engine_seconds_completed) }}</span>
        </span>
      </template>
      <template v-slot:item.doneTime="{ item }">
        <span v-if="item.completed !== null">
          <span>{{ $moment(item.completed).format("YYYY-MM-DD") }}</span>
        </span>
      </template>
      <template v-slot:item.action="{ item }">
        <div
          style="height: 40px"
          class="pt-2"
          v-if="item.crm_internal_task === null"
        >
          <v-icon class="mx-2 edit--hover" @click="createTask(item)"
            >{{ item.icon }}
          </v-icon>
        </div>
        <div v-else style="height: 40px" class="pt-2">
          <v-icon
            class="mx-2 edit--hover"
            @click="openTask(item.crm_internal_task.uuid)"
          >
            mdi-link
          </v-icon>
        </div>
      </template>
      <template v-slot:item.is_critical="{ item }">
        <v-chip
          v-if="item.service_status === 'complete'"
          class="ma-2"
          dark
          color="green"
        >
          Done
        </v-chip>
        <v-chip
          v-if="item.service_status === 'overdue'"
          class="ma-2"
          dark
          color="red"
        >
          Overdue
        </v-chip>
        <v-chip v-if="item.requires_attention" class="ma-2" dark color="red">
          Due Soon
        </v-chip>
        <v-chip
          v-if="!item.requires_attention && item.service_status === 'upcoming'"
          class="ma-2"
          dark
          color="orange"
        >
          Upcoming
        </v-chip>
      </template>
      <template v-slot:item.crm_internal_task="{ item }">
        <v-chip
          class="ma-2"
          dark
          color="green"
          v-if="item.crm_internal_task !== null"
        >
          <span v-if="item.crm_internal_task.assignee_status === 'PENDING'">
            created</span
          >
          <span v-else>Assigned</span>
        </v-chip>
        <!-- <v-chip v-else class="ma-2" dark color="orange">
          Pending
        </v-chip> -->
      </template>
      <template v-slot:item.crm_service="{ item }">
        <span v-if="item.crm_service !== null">{{
          item.crm_service.short_task
        }}</span>
      </template>
      <template v-slot:item.should_be_done_before="{ item }">
        <span v-if="getDaysLeftDate(item.should_be_done_before) !== null">
          <span v-if="getDaysLeftDate(item.should_be_done_before) > 0">
            {{ getDaysLeftDate(item.should_be_done_before) }} days left</span
          ><span v-if="getDaysLeftDate(item.should_be_done_before) <= 0">
            {{ Math.abs(getDaysLeftDate(item.should_be_done_before)) }} days
            ago</span
          ></span
        >
      </template>
      <template v-slot:item.should_be_engine_seconds_before="{ item }">
        <span
          v-if="
            getEngineHours(item.engine_seconds) !== 0 &&
              getEngineHours(item.should_be_engine_seconds_before) !== 0
          "
          >{{ getEngineHours(item.engine_seconds) }}/{{
            getEngineHours(item.should_be_engine_seconds_before)
          }}
        </span>
      </template>

      <template v-slot:item.crm_boat="{ item }">
        <div v-if="item.crm_boat">
          <span v-if="item.crm_boat"
            >{{ item.crm_boat.model.brand_name }}
            {{ item.crm_boat.model.model }}
          </span>
          <!-- <v-btn icon @click="openBoat(item.crm_boat.uuid)">
            <v-icon color="primary">mdi-link</v-icon>
          </v-btn> -->
        </div>
      </template>
      <template v-slot:item.crm_boat_item="{ item }">
        <div v-if="item.crm_boat_item">
          <span>{{ item.crm_boat_item.crm_item.name }} </span>
          <!-- <v-btn icon @click="openItem(item.crm_boat_item.uuid)">
            <v-icon color="primary">mdi-link</v-icon>
          </v-btn> -->
        </div>
      </template>
    </v-data-table>
    <v-pagination
      v-model="currentPage"
      :length="numberOfPages"
      :total-visible="7"
      color="primary"
    />
  </div>
</template>

<script>
// import debounce from "@/plugins/debounce";
// import constraints from "@/utils/constraints";
// import api from "@/utils/api";
// import BoatSelector from "@/components/selectors/BoatSelector.vue";

export default {
  props: {
    title: String,
    headers: Array,
    data: Array,
    sort: String,
    loading: Boolean,
    response: Object,
  },

  components: {
    // BoatSelector,
  },

  data: () => ({
    canUseKnowledgeBaseBtn: false,
    helpDialog: { location: null, active: false },
    search: "",
    page: 1,
    selected: [],
    statusFilterSelected: "",
    statusFilterOptions: [],
    boatOptions: [
      {
        uuid: "74578e733a51403d99d6435b53a48fdb",
        model: {
          uuid: "8817068fd051410eb6d5c408467234e3",
          brand_name: "Unknown brand⁣",
          model: "Unknown model",
        },
        boat_name: "Tipsy",
        pretty_name: "Unknown model",
        device_serial: "Test Device 7850",
        slot_number: null,
        ce_number: null,
        is_user_present: true,
      },
    ],
    boat: null,
  }),
  created() {
    this.setInitalData();
    if (
      this.$route.query.page ||
      this.$route.query.crm_boat_uuid ||
      this.$route.query.service_status
    ) {
      this.search = this.$route.query.crm_boat_uuid || "";
      this.currentPage = Number(this.$route.query.page) || 1;
      this.statusFilterSelected = this.$route.query.service_status;
    }
    this.boat = this.boatOptions[0];
    // this.canUseKnowledgeBaseBtn =
    //   constraints.canUseKnowledgeBaseBtn("services");
  },

  computed: {
    formTitle() {
      return this.title.substring(0, this.title.length - 1);
    },
    numberOfPages() {
      if (!this.response) {
        return 0;
      }
      return parseInt(Math.ceil(this.response.count / 24));
    },
    currentPage: {
      get: function() {
        return this.page;
      },
      set: function(value) {
        if (value !== this.page) {
          this.page = value;
          // this.$emit("reload-content", this.setQueryParameters());
          // this.$router.replace({
          //   name: this.$route.name,
          //   query: this.setQueryParameters(),
          // });
        }
      },
    },
  },
  methods: {
    setInitalData() {
      this.statusFilterSelected = "";
      this.statusFilterOptions = [
        {
          name: "All",
          value: "",
        },
        {
          name: "Done",
          value: "complete",
        },
        {
          name: "Overdue",
          value: "overdue",
        },
        {
          name: "Upcoming",
          value: "upcoming",
        },
      ];
    },
    handleSelectedRow(item) {
      if (typeof item.item === "undefined") {
        this.data.forEach((element) => {
          element.icon = item.value
            ? "mdi-checkbox-multiple-marked-circle-outline"
            : "mdi-calendar-plus";
        });
        return;
      }

      let index = this.data.findIndex(
        (element) => element.uuid === item.item.uuid
      );
      this.data[index].icon = item.value
        ? "mdi-checkbox-multiple-marked-circle-outline"
        : "mdi-calendar-plus";
    },
    handleBoatSelection(event) {
      this.search = event;
      this.submitSearch();
    },
    createTask(service) {
      // if (this.selected.length >= 2) {
      //   this.$router.pushAsync({ name: "tasksNew" }).then(() => {
      //     this.$store.commit("SET_SERVICES", {
      //       from: "services",
      //       services: this.selected,
      //     });
      //   });
      // } else {
      //   this.$router.pushAsync({ name: "tasksNew" }).then(() => {
      //     this.$store.commit("SET_SERVICES", {
      //       from: "services",
      //       services: [service],
      //     });
      //   });
      // }
    },
    setQueryParameters() {
      let params = {
        crm_boat_uuid: this.search,
        page: this.page,
      };

      if (this.statusFilterSelected !== "all") {
        params.service_status = this.statusFilterSelected;
      }

      return params;
    },
    openTask(uuid) {
      this.$emit("task", uuid);
    },
    openItem(uuid) {
      this.$emit("item", uuid);
    },
    getDaysLeftDate(item) {
      if (item === null) {
        return null;
      }

      return this.$moment(item).diff(this.$moment(), "days");
    },
    getEngineHours(item) {
      return Math.round(item / 3600);
    },
    closeHelpDialog(event) {
      if (event === "close") {
        this.helpDialog.active = false;
      }
    },
    handleHelp(event) {
      this.helpDialog.active = true;
      this.helpDialog.location = "services";
    },
    previewItem(event) {
      this.$emit("preview", event);
    },
    submitSearch(event) {
      // this.page = 1;
      // this.$router.replace({
      //   name: this.$route.name,
      //   query: this.setQueryParameters(),
      // });
      // this.$emit("reload-content", this.setQueryParameters());
    },
  },
};
</script>

<style lang="scss">
.text--capitalize {
  text-transform: capitalize;
}

.delete--hover {
  &:hover {
    color: red;
  }
}

.preview--hover {
  &:hover {
    color: --secondary;
  }
}

.edit--hover {
  &:hover {
    color: --primary;
  }
}
.copy--hover {
  &:hover {
    color: --primary;
  }
}
</style>
