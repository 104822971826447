<template>
  <v-container>
    <maintenance-list
      :title="title"
      :headers="headers"
      :data="data"
      :response="response"
      :loading="loading"
      @task="handlePreview"
      @reload-content="handleReload"
      @preview="openServiceDetails"
    />
  </v-container>
  <!-- <service-details-dialog
      v-if="serviceDetailsDialog.active"
      :dialog="serviceDetailsDialog"
      @close="closeServiceDetailsDialog"
    ></service-details-dialog> -->
</template>

<script>
import api from "@/utils/api.js";
import MaintenanceList from "@/components/lists/MaintenanceList.vue";
// import ServiceDetailsDialog from "@/components/dialogs/ServiceDetailsDialog";
import snackbar from "@/utils/snackbar.js";
export default {
  components: {
    MaintenanceList,
    // ServiceDetailsDialog,
  },
  data: () => ({
    headers: [],
    data: [],
    sort: "name",
    title: "Maintenance",
    deleteDialog: false,
    boat: null,
    boatName: null,
    loading: true,
    response: null,
    forceRefreshServiceDetails: 0,
    serviceDetailsDialog: { active: false, details: null },
  }),
  created() {
    this.setInitalData();
    if (this.$route.query.service_status === "overdue") {
      this.headers = this.headers.filter((element) => !element.canEdit);
      this.headers = [
        ...this.headers,
        ...this.setOverdueTemplate(),
      ].sort((a, b) => (a.index > b.index ? 1 : b.index > a.index ? -1 : 0));
    } else if (this.$route.query.service_status === "complete") {
      this.headers = this.headers.filter((element) => !element.canEdit);
      this.headers = [...this.headers, ...this.setDoneTemplate()].sort((a, b) =>
        a.index > b.index ? 1 : b.index > a.index ? -1 : 0
      );
    }

    if (this.$route.query.page || this.$route.query.search) {
      this.loadTasks(this.$route.query);
    } else {
      this.loadTasks();
    }
  },
  methods: {
    setInitalData() {
      this.setHeaders();
      this.title = "Maintenance recommendation";
    },
    setHeaders() {
      this.headers = [
        {
          text: "Boat",
          align: "left",
          value: "crm_boat",
          sortable: false,
          canEdit: false,
          index: 0,
        },
        {
          text: "Item",
          align: "left",
          value: "crm_boat_item",
          sortable: false,
          canEdit: false,
          index: 1,
        },
        {
          text: "Task Name",
          align: "left",
          value: "crm_service",
          sortable: false,
          canEdit: false,
          index: 2,
        },
        {
          text: "Status",
          align: "center",
          value: "is_critical",
          sortable: false,
          canEdit: false,
          index: 3,
        },
        {
          text: "Mileage",
          align: "left",
          value: "should_be_engine_seconds_before",
          sortable: false,
          canEdit: true,
          index: 4,
        },
        {
          text: "Time",
          align: "left",
          value: "should_be_done_before",
          sortable: false,
          canEdit: true,
          index: 5,
        },
        {
          text: "Task Status",
          align: "center",
          value: "crm_internal_task",
          sortable: false,
          canEdit: false,
          index: 6,
        },
        {
          text: "",
          align: "right",
          value: "action",
          sortable: false,
          canEdit: true,
          index: 7,
        },
      ];
    },
    setDoneTemplate() {
      return [
        {
          text: "Mileage",
          align: "left",
          value: "doneMileage",
          canEdit: true,
          sortable: false,
          index: 4,
        },
        {
          text: "Time",
          align: "left",
          value: "doneTime",
          canEdit: true,
          sortable: false,
          index: 5,
        },
      ];
    },
    setOverdueTemplate() {
      return [
        {
          text: "Mileage",
          align: "left",
          value: "overdueMileage",
          canEdit: true,
          sortable: false,
          index: 4,
        },
        {
          text: "Time",
          align: "left",
          value: "overdueTime",
          canEdit: true,
          sortable: false,
          index: 5,
        },
        {
          text: "",
          align: "right",
          value: "action",
          sortable: false,
          canEdit: true,
          index: 7,
        },
      ];
    },
    handleReload(event) {
      if (event.service_status === "overdue") {
        this.headers = this.headers.filter((element) => !element.canEdit);
        this.headers = [
          ...this.headers,
          ...this.setOverdueTemplate(),
        ].sort((a, b) => (a.index > b.index ? 1 : b.index > a.index ? -1 : 0));
      } else if (event.service_status === "complete") {
        this.headers = this.headers.filter((element) => !element.canEdit);
        this.headers = [
          ...this.headers,
          ...this.setDoneTemplate(),
        ].sort((a, b) => (a.index > b.index ? 1 : b.index > a.index ? -1 : 0));
      } else {
        this.setHeaders();
      }

      this.loadTasks(event);
    },
    handlePreview(uuid) {
      //   this.$router.push({
      //     name: "tasksDetails",
      //     params: { id: uuid },
      //   });
    },
    openServiceDetails(event) {
      this.serviceDetailsDialog = { active: true, details: event };
    },
    closeServiceDetailsDialog() {
      this.serviceDetailsDialog = { active: false, details: null };
    },
    loadTasks(params) {
      if (!params) {
        params = {};
      }
      this.loading = true;
      this.data = [];

      api.getMaintenanceList(params).then((resp) => {
        this.response = resp;
        this.data = resp.results.map((element) => {
          return {
            ...element,
            icon: "mdi-calendar-plus",
          };
        });
        this.loading = false;
      });
    },
  },
};
</script>
