var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1 text-overflow my-10",attrs:{"show-select":"","headers":_vm.headers,"items":_vm.data,"loading":_vm.loading,"hide-default-footer":"","items-per-page":24,"item-key":"uuid"},on:{"item-selected":_vm.handleSelectedRow,"toggle-select-all":_vm.handleSelectedRow,"click:row":_vm.previewItem},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","height":"70"}},[_c('v-col',{staticClass:"pl-0",attrs:{"cols":"5","sm":"6","md":"4","lg":"4","xl":"3"}},[_c('v-row',{staticClass:"ml-1",attrs:{"justify":"start"}},[_c('v-toolbar-title',{staticClass:"headline",attrs:{"data-cy":_vm.title}},[_c('span',[_vm._v(" "+_vm._s(_vm.title)+" ")]),(_vm.canUseKnowledgeBaseBtn)?_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":_vm.handleHelp}},[_c('v-icon',[_vm._v("mdi-help-circle")])],1):_vm._e()],1)],1)],1),_c('v-spacer'),_c('v-col',{staticClass:"mt-7",attrs:{"cols":"5","lg":"4","xl":"4"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"5"}},[_c('v-select',{attrs:{"items":_vm.boatOptions,"item-text":"boat_name"},model:{value:(_vm.boat),callback:function ($$v) {_vm.boat=$$v},expression:"boat"}})],1),_c('v-col',{attrs:{"cols":"3","md":"4","sm":"4"}},[_c('v-select',{attrs:{"items":_vm.statusFilterOptions,"item-text":"name","item-value":"value","color":"primary"},on:{"change":_vm.submitSearch},model:{value:(_vm.statusFilterSelected),callback:function ($$v) {_vm.statusFilterSelected=$$v},expression:"statusFilterSelected"}})],1)],1)],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"2"}})],1)]},proxy:true},{key:"item.overdueMileage",fn:function(ref){
var item = ref.item;
return [_c('span',[(
            _vm.getEngineHours(item.engine_seconds) -
              _vm.getEngineHours(item.should_be_engine_seconds_before) >
              0
          )?_c('span',[_vm._v(" "+_vm._s(_vm.getEngineHours(item.engine_seconds) - _vm.getEngineHours(item.should_be_engine_seconds_before))+" hours ago ")]):_c('span',[_vm._v(" "+_vm._s(_vm.getEngineHours(item.engine_seconds) - _vm.getEngineHours(item.should_be_engine_seconds_before))+" hours left ")])])]}},{key:"item.overdueTime",fn:function(ref){
          var item = ref.item;
return [(_vm.getDaysLeftDate(item.should_be_done_before) !== null)?_c('span',[(_vm.getDaysLeftDate(item.should_be_done_before) > 0)?_c('span',[_vm._v(" "+_vm._s(_vm.getDaysLeftDate(item.should_be_done_before))+" days left")]):_vm._e(),(_vm.getDaysLeftDate(item.should_be_done_before) <= 0)?_c('span',[_vm._v(" "+_vm._s(Math.abs(_vm.getDaysLeftDate(item.should_be_done_before)))+" days ago")]):_vm._e()]):_vm._e()]}},{key:"item.doneMileage",fn:function(ref){
          var item = ref.item;
return [(item.engine_seconds_completed !== null)?_c('span',[_c('span',[_vm._v(_vm._s(_vm.getEngineHours(item.engine_seconds_completed)))])]):_vm._e()]}},{key:"item.doneTime",fn:function(ref){
          var item = ref.item;
return [(item.completed !== null)?_c('span',[_c('span',[_vm._v(_vm._s(_vm.$moment(item.completed).format("YYYY-MM-DD")))])]):_vm._e()]}},{key:"item.action",fn:function(ref){
          var item = ref.item;
return [(item.crm_internal_task === null)?_c('div',{staticClass:"pt-2",staticStyle:{"height":"40px"}},[_c('v-icon',{staticClass:"mx-2 edit--hover",on:{"click":function($event){return _vm.createTask(item)}}},[_vm._v(_vm._s(item.icon)+" ")])],1):_c('div',{staticClass:"pt-2",staticStyle:{"height":"40px"}},[_c('v-icon',{staticClass:"mx-2 edit--hover",on:{"click":function($event){return _vm.openTask(item.crm_internal_task.uuid)}}},[_vm._v(" mdi-link ")])],1)]}},{key:"item.is_critical",fn:function(ref){
          var item = ref.item;
return [(item.service_status === 'complete')?_c('v-chip',{staticClass:"ma-2",attrs:{"dark":"","color":"green"}},[_vm._v(" Done ")]):_vm._e(),(item.service_status === 'overdue')?_c('v-chip',{staticClass:"ma-2",attrs:{"dark":"","color":"red"}},[_vm._v(" Overdue ")]):_vm._e(),(item.requires_attention)?_c('v-chip',{staticClass:"ma-2",attrs:{"dark":"","color":"red"}},[_vm._v(" Due Soon ")]):_vm._e(),(!item.requires_attention && item.service_status === 'upcoming')?_c('v-chip',{staticClass:"ma-2",attrs:{"dark":"","color":"orange"}},[_vm._v(" Upcoming ")]):_vm._e()]}},{key:"item.crm_internal_task",fn:function(ref){
          var item = ref.item;
return [(item.crm_internal_task !== null)?_c('v-chip',{staticClass:"ma-2",attrs:{"dark":"","color":"green"}},[(item.crm_internal_task.assignee_status === 'PENDING')?_c('span',[_vm._v(" created")]):_c('span',[_vm._v("Assigned")])]):_vm._e()]}},{key:"item.crm_service",fn:function(ref){
          var item = ref.item;
return [(item.crm_service !== null)?_c('span',[_vm._v(_vm._s(item.crm_service.short_task))]):_vm._e()]}},{key:"item.should_be_done_before",fn:function(ref){
          var item = ref.item;
return [(_vm.getDaysLeftDate(item.should_be_done_before) !== null)?_c('span',[(_vm.getDaysLeftDate(item.should_be_done_before) > 0)?_c('span',[_vm._v(" "+_vm._s(_vm.getDaysLeftDate(item.should_be_done_before))+" days left")]):_vm._e(),(_vm.getDaysLeftDate(item.should_be_done_before) <= 0)?_c('span',[_vm._v(" "+_vm._s(Math.abs(_vm.getDaysLeftDate(item.should_be_done_before)))+" days ago")]):_vm._e()]):_vm._e()]}},{key:"item.should_be_engine_seconds_before",fn:function(ref){
          var item = ref.item;
return [(
          _vm.getEngineHours(item.engine_seconds) !== 0 &&
            _vm.getEngineHours(item.should_be_engine_seconds_before) !== 0
        )?_c('span',[_vm._v(_vm._s(_vm.getEngineHours(item.engine_seconds))+"/"+_vm._s(_vm.getEngineHours(item.should_be_engine_seconds_before))+" ")]):_vm._e()]}},{key:"item.crm_boat",fn:function(ref){
        var item = ref.item;
return [(item.crm_boat)?_c('div',[(item.crm_boat)?_c('span',[_vm._v(_vm._s(item.crm_boat.model.brand_name)+" "+_vm._s(item.crm_boat.model.model)+" ")]):_vm._e()]):_vm._e()]}},{key:"item.crm_boat_item",fn:function(ref){
        var item = ref.item;
return [(item.crm_boat_item)?_c('div',[_c('span',[_vm._v(_vm._s(item.crm_boat_item.crm_item.name)+" ")])]):_vm._e()]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('v-pagination',{attrs:{"length":_vm.numberOfPages,"total-visible":7,"color":"primary"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }